const dark = {
	title: "dark",

	colors: {
		primary: "#0c0c0c",
		secundary: "#06c",

		background: "#1d1d1f",
		background2: "#202020",

		text: "#d8d8d8",
		text2: "#ffffff",

		overlay: "#d8d8d860",
		overlay2: "#151517",
	},
};

export default dark;
