import styled from "styled-components";
import { device } from "../../styles/GlobalStyle";
import { Link } from "react-router-dom";

export const StyledNovelCard = styled.div`
	width: 30%;
	max-width: 12rem;
	border-bottom-left-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
	color: ${(props) => props.theme.colors.text};
	padding: 7px;
	margin: 7px auto;

	@media ${device.mobileL} {
		max-width: 6rem;
	}
	@media ${device.tablet} {
		max-width: 10rem;
	}
	@media ${device.laptop} {
		max-width: 11rem;
	}
`;

export const StyledNovelPic = styled.img`
	width: 100%;
	height: auto;
	//border: 5px solid red;
	&:hover {
		color: ${(props) => props.theme.colors.text2};
	}
`;

export const StyledNovelSkeletonPic = styled.div`
	display: flex;
	width: 100%;
	height: 10.6rem;
	margin-bottom: 0.8rem;

	@media (min-width: 0px) and (max-width: 549px) {
		height: 10.5rem;
	}
	@media (min-width: 550px) and (max-width: 599px) {
		height: 11rem;
	}
	@media (min-width: 600px) and (max-width: 767px) {
		height: 13.7rem;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		height: 14.5rem;
	}
	@media (min-width: 1024px) {
		height: 16rem;
	}

	&.max-container {
		width: 100%;
		height: 100%;
	}
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${(props) => props.theme.colors.text};
`;

export const StyledBottomCard = styled.div`
	width: 100%;
	padding: 0.1rem;
	height: auto;
	min-height: 4rem;

	&.skeleton {
		line-height: 1.4rem;
	}

	div {
		width: 100%;
		max-height: 2.6rem;
		line-height: 1.3rem;
		height: 3rem;

		position: relative;
		overflow: hidden;
		:after {
			content: "";
			text-align: right;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 70%;
			height: 1.2em;
			background: linear-gradient(
				to right,
				rgba(255, 255, 255, 0),
				${(props) => props.theme.colors.overlay2} 50%
			);
		}
	}

	&:hover {
		color: ${(props) => props.theme.colors.text2};
	}

	.chapter {
		background-color: ${(props) => props.theme.colors.overlay};
		padding: 3px;
		border-radius: 4px;
		//border-radius: 4px;
	}

	:hover {
		color: ${(props) => props.theme.colors.text2};
	}

	@media ${device.tablet} {
		line-height: 1rem;
		font-size: 14px;
	}
`;
