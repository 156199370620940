import styled from "styled-components";

export const StyledAboutContent = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
	font-size: 16px;
	line-height: 24px;
`;

export const StyledTitle = styled.h1`
	color: ${(props) => props.theme.colors.text2};
	text-align: center;
	padding: 2rem;
	line-height: 40px;
`;
