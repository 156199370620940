import React from "react";
import {
	StyledHeader,
	Nav,
	StyledLink,
	NavLinksContainer,
	StyledSpan,
} from "./styles";

const Header: React.FC = () => {
	return (
		<StyledHeader>
			<Nav>
				<StyledLink to="/">
					<img
						src={process.env.PUBLIC_URL + "/img/Logo.png"}
						alt="Logo"
						width="80px"
					/>
				</StyledLink>

				<NavLinksContainer>
					<StyledLink to="/novels">
						<StyledSpan>Novels</StyledSpan>
					</StyledLink>
					<StyledLink to="/about">
						<StyledSpan>Sobre</StyledSpan>
					</StyledLink>
				</NavLinksContainer>
			</Nav>
		</StyledHeader>
	);
};

export default Header;
