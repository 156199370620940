import React from "react";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { StyledErrorPage } from "./styles";

const ErrorPage: React.FC = () => {
	useDocumentTitle("Erro!");
	return (
		<StyledErrorPage>
			<h1>Oops!</h1>
			<p>Desculpa, aconteceu um erro inesperado.</p>
			<br />
			<img
				src={process.env.PUBLIC_URL + "/img/paneNoSistema.jpg"}
				alt="Pane no sistema"
				width="300px"
			/>
		</StyledErrorPage>
	);
};

export default ErrorPage;
