import styled from "styled-components";
import { device } from "../../styles/GlobalStyle";

export const StyledLatestChapters = styled.div`
	width: 80%;
	max-width: 1024px;
	height: auto;
	min-height: 3rem;
	margin: 0 auto;
	padding: 0.3rem;
	margin: 0 auto;
	background-color: ${(props) => props.theme.colors.overlay2};
	border-radius: 0.8rem;

	div {
		display: inline-block;
	}

	@media ${device.tablet} {
		width: 100%;
	}
`;

export const StyledHR = styled.hr`
	width: 80%;
	border: 1px solid ${(props) => props.theme.colors.overlay};
	margin: 10px auto;
`;

export const StyledTitle = styled.h3`
	width: 100%;
	text-align: center;
	margin: 0.5rem;
`;
