import styled from "styled-components";

export const StyledAllNovels = styled.div`
	width: 100%;
	height: auto;
	min-height: 3rem;
	padding: 1rem;
	max-width: 1024px;
	margin: 0 auto;
	background-color: ${(props) => props.theme.colors.overlay2};
	border-radius: 0.8rem;

	div {
		display: inline-block;
	}
`;

export const StyledHR = styled.hr`
	width: 80%;
	border: 1px solid ${(props) => props.theme.colors.overlay};
	margin: 10px auto;
`;

export const StyledTitle = styled.h3`
	width: 100%;
	text-align: center;
	margin: 0.5rem;
`;
