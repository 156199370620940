import React from "react";
import {
	StyledFooter,
	StyledFooterContainer,
	StyledBottomContainer,
	StyledHR,
	StyledIconsContainer,
	StyledLink,
} from "./styles";

const Footer: React.FC = () => {
	return (
		<StyledFooter>
			<StyledFooterContainer>
				<StyledIconsContainer>
					<StyledLink to="/">
						<img
							src={process.env.PUBLIC_URL + "/img/Logo.png"}
							alt="Logo"
							width="100px"
						/>
						<br />
						<img
							src={
								process.env.PUBLIC_URL + "/img/translations.png"
							}
							alt="Logo"
							width="85px"
						/>
					</StyledLink>
				</StyledIconsContainer>

				<StyledHR />
				<StyledBottomContainer>
					<span>Copyright © Asgard Traduções 2023</span>
					<span>Desenvolvido por Kam1</span>
				</StyledBottomContainer>
			</StyledFooterContainer>
		</StyledFooter>
	);
};

export default Footer;
