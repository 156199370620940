import React, { useEffect, useState } from "react";
import HomeCarousel from "../../components/HomeCarousel";
import { StyledPage, StyledMiddleContentM } from "../../styles/GlobalStyle";
import LatestChapters from "../../components/LatestChapters";
import api from "../../api/api";
import useDocumentTitle from "../../utils/useDocumentTitle";

const Home: React.FC = () => {
	const [newChapters, setNewChapters] = useState([]);
	const [loading, setLoading] = useState(true);
	const getLastChapters = async () => {
		try {
			const { data } = await api.get("novel/lastchapters");
			setNewChapters(data);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		getLastChapters();
	}, []);

	useDocumentTitle("");

	return (
		<StyledPage>
			<StyledMiddleContentM className="home">
				<HomeCarousel />
				<LatestChapters chapters={newChapters} loading={loading} />
			</StyledMiddleContentM>
		</StyledPage>
	);
};

export default Home;
