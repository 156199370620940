import React from "react";
import { IChapters } from "./types";
import {
	StyledListOfChapters,
	StyledTitle,
	StyledSkeletonChapter,
} from "./styles";
import ChapterCard from "./ChapterCard";
import Skeleton from "react-loading-skeleton";

const ListOfChapters: React.FC<IChapters> = (props) => {
	return (
		<StyledListOfChapters>
			<StyledTitle>Lista de Capítulos</StyledTitle>
			{props?.loading ? (
				<ChapterSkeleton />
			) : (
				props.chapters?.map((chapter, index) => (
					<ChapterCard
						key={index}
						chname={chapter.name_ptbr}
						chnumber={chapter.number}
						url={`${chapter.number}`}
					/>
				))
			)}
			{}
		</StyledListOfChapters>
	);
};

export default ListOfChapters;

const ChapterSkeleton: React.FC = () => {
	return (
		<StyledSkeletonChapter>
			<Skeleton
				count={1}
				height="100%"
				containerClassName="max-container"
			/>
		</StyledSkeletonChapter>
	);
};
