import React from "react";
import {
	StyledNovelCard,
	StyledNovelSkeletonPic,
	StyledBottomCard,
} from "./styles";
import Skeleton from "react-loading-skeleton";

const NovelCardSkeleton: React.FC = () => {
	return (
		<StyledNovelCard>
			<StyledNovelSkeletonPic>
				<Skeleton
					count={1}
					height="100%"
					containerClassName="max-container"
				/>
			</StyledNovelSkeletonPic>

			<StyledBottomCard className="skeleton">
				<Skeleton count={3} />
			</StyledBottomCard>
		</StyledNovelCard>
	);
};

export default NovelCardSkeleton;
