import React from "react";
import { StyledChapter, StyledLink } from "./styles";
import { IChapter } from "./types";

const ChapterCard: React.FC<IChapter> = (props) => {
	return (
		<StyledLink to={props.url}>
			<StyledChapter>
				Capítulo {props.chnumber} : {props.chname}
			</StyledChapter>
		</StyledLink>
	);
};

export default ChapterCard;
