import React from "react";
import { INovelAbout } from "./types";
import {
	StyledNovelAbout,
	StyledNovelPic,
	StyledUperContainer,
	StyledInformationContainer,
	StyledTitle,
	StyledHR,
	StyledNovelInfo,
	StyledInfoLeft,
	StyledInfoRight,
	StyledSumaryText,
	StyledSubTitle,
	StyledSkeletonNovelPic,
} from "./styles";
import Skeleton from "react-loading-skeleton";

// status é pra significar se a novel  já foi finalizada(0),   está em hiato (1),   está sendo ainda traduzida(2)

const NovelAbout: React.FC<INovelAbout> = (props) => {
	const sumary = props?.sumary?.split(/[\r\n]+/);

	return (
		<StyledNovelAbout>
			<StyledUperContainer>
				{props.loading ? (
					<ImageSkeleton />
				) : (
					<StyledNovelPic
						src={process.env.PUBLIC_URL + props.novelimgsrc}
					/>
				)}

				<StyledInformationContainer>
					<StyledTitle>
						{props.novelname_ptbr || <Skeleton count={1} />}
					</StyledTitle>
					<StyledSubTitle>
						{props.novelname || <Skeleton count={1} />}
					</StyledSubTitle>
					<StyledHR />
					<StyledNovelInfo>
						<StyledInfoLeft>
							<span>
								Status:{" "}
								{props.status === 0
									? "Finalizado"
									: props.status === 1
									? "Em Hiato"
									: "Em Andamento"}
							</span>
							<span>
								Autor: {props.author || <Skeleton count={1} />}
							</span>
							<span>
								Tradutor:{" "}
								{props.translator || <Skeleton count={1} />}
							</span>
							<span>
								Editor: {props.editor || <Skeleton count={1} />}
							</span>
							<span>
								Capítulos:{" "}
								{props.chaptersqtd || <Skeleton count={1} />}
							</span>
							<div>
								{props.genre?.length ? (
									<>
										Gêneros:
										{props.genre?.map((genre) => (
											<span key={genre.name}>
												{genre.name}{" "}
											</span>
										))}
									</>
								) : (
									<></>
								)}
							</div>
							<div>
								{props.tags?.length ? (
									<>
										Tags:
										{props.tags?.map((tag) => (
											<span key={tag.name}>
												{tag.name}{" "}
											</span>
										))}
									</>
								) : (
									<></>
								)}
							</div>
						</StyledInfoLeft>
						<StyledInfoRight>
							<StyledSumaryText>Sumário</StyledSumaryText>
							{props.loading && <Skeleton count={5} />}
							{sumary?.map((word) => (
								<p key={word}>
									<span>{word}</span>
									<br />
								</p>
							))}
						</StyledInfoRight>
					</StyledNovelInfo>
				</StyledInformationContainer>
			</StyledUperContainer>
		</StyledNovelAbout>
	);
};

export default NovelAbout;

const ImageSkeleton: React.FC = () => {
	return (
		<StyledSkeletonNovelPic>
			<Skeleton
				count={1}
				height="100%"
				containerClassName="max-container"
			/>
		</StyledSkeletonNovelPic>
	);
};
