import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Outlet } from "react-router-dom";
import { StyledDefault } from "./styles";

const Default: React.FC = () => {
	return (
		<StyledDefault>
			<Header />
			<Outlet />
			<Footer />
		</StyledDefault>
	);
};

export default Default;
