import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../styles/GlobalStyle";

export const StyledChapter = styled.div`
	width: 100%;
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
`;

export const StyledTextContainer = styled.div`
	font-size: 16px;
	margin: 10px 0;
	line-height: 24px;
	p {
		margin: 20px 0;
	}
`;

export const StyledTitleContainer = styled.div`
	width: auto;
	height: auto;
	padding: 0 0 20px;
	margin-bottom: 2rem;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.title {
		font-size: 26px;
		font-weight: 700;
		line-height: 155%;

		font-style: normal;
		letter-spacing: -0.02em;

		@media ${device.tablet} {
			font-size: 18px;
		}
	}

	.translator {
		font-size: 1rem;
		line-height: 1rem;
	}

	.date {
		font-size: 0.75rem;
	}

	.btns {
		display: flex;
		padding-left: 0.5rem;
		align-items: right;
	}
`;

export const StyledChBtnsContainer = styled.div`
	height: 3rem;
	min-height: 15px;
	width: 100%;
	.prev-next-btn {
		height: 100%;
		width: 40%;
	}
	.toc {
		height: 100%;
		width: 20%;
	}
	.text-rotate {
		transform: rotate(90deg);
	}
`;

export const StyledTopChapter = styled.div`
	height: 5rem;
	min-height: 15px;
	width: 100%;
	font-weight: 700;
	//background-color: #381c2f;
	border: 1px solid ${(props) => props.theme.colors.background2};
	margin: 1rem auto;
	display: flex;
	align-items: left;
`;

export const StyledNovelName = styled.div`
	width: auto;
	height: 100%;
	padding: 0.5rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	color: ${(props) => props.theme.colors.text2};
	&:hover {
		color: ${(props) => props.theme.colors.text};
	}
`;

export const StyledImg = styled.img`
	height: 100%;
	width: auto;
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${(props) => props.theme.colors.text2};
	background-color: transparent;
`;

export const FootNotesContainer = styled.div`
	height: auto;
	width: 85%;
	margin: 2rem auto;
	padding: 1.5rem;
	border-radius: 0.7rem;
	background-color: ${(props) => props.theme.colors.background2};
	color: ${(props) => props.theme.colors.text};
	span {
		font-weight: 700;
	}
`;

export const StyledHR = styled.hr`
	width: 80%;
	border: 1px solid ${(props) => props.theme.colors.overlay};
	margin: 10px auto;
`;

export const StyledChapterImg = styled.img`
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: auto;
	max-width: 80%;
`;

export const StyledHandwriting2 = styled.span`
	font-family: "Italianno", cursive;
	font-size: 2rem;
	line-height: 2rem;
	text-shadow: #ff0000 1px 0 10px;
	padding: 0 3rem;
`;

export const StyledHandwriting = styled.span`
	font-size: 1.7rem;
	line-height: 2rem;
	text-shadow: #fc0 1px 0 10px;
	padding: 0 3rem;
	font-family: "Niconne", cursive;
`;

export const StyledSurpriseText = styled.span`
	font-size: 1.4rem;
	line-height: 2rem;
	text-shadow: #00f 1px 0 10px;
	padding: 0 3rem;
	display: block;
	text-align: center;
`;
