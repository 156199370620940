import React, { useEffect, useCallback, useContext } from "react";
import {
	StyledChapter,
	StyledTextContainer,
	StyledTitleContainer,
	StyledChBtnsContainer,
	StyledLink,
	StyledTopChapter,
	StyledImg,
	StyledNovelName,
	FootNotesContainer,
	StyledHR,
	StyledChapterImg,
	StyledHandwriting,
	StyledHandwriting2,
	StyledSurpriseText,
} from "./styles";
import { ChapterI } from "./types";
import { Button } from "@mui/material";
import { ThemeContext } from "styled-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router";
import reactStringReplace from "react-string-replace";
import Skeleton from "react-loading-skeleton";
import TranslatorNotes from "./TranslatorNotes";

const Chapter: React.FC<ChapterI> = (props) => {
	const navigate = useNavigate();
	const goToNextChapter = useCallback(() => {
		if (props.nextCh) {
			//console.log("Devo ir para o próximo Capitulo");
			navigate(props.nextCh);
		} else {
			console.log("Não existe um próximo Capitulo no momento");
		}
	}, [props.nextCh, navigate]);

	const goToPrevChapter = useCallback(() => {
		if (props.prevCh) {
			//console.log("Devo ir para o Capitulo anterior");
			navigate(props.prevCh);
		} else {
			console.log("Não existe um capitulo anterior");
		}
	}, [props.prevCh, navigate]);

	const formatDate = (posdate: Date) => {
		let date: Date = new Date(posdate);
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		const months = [
			"Janeiro",
			"Fevereiro",
			"Março",
			"Abril",
			"Maio",
			"Junho",
			"Julho",
			"Agosto",
			"Setembro",
			"Outubro",
			"Novembro",
			"Dezembro",
		];
		return `${day} de ${months[month]} de ${year}`;
	};

	useEffect(() => {
		document.addEventListener("keydown", (e: KeyboardEventInit) => {
			if (e.ctrlKey && e.key === "ArrowRight") {
				goToNextChapter();
			} else if (e.ctrlKey && e.key === "ArrowLeft") {
				goToPrevChapter();
			}
		});
	}, [goToNextChapter, goToPrevChapter]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [props.number]);

	const { colors } = useContext(ThemeContext);
	// dou um split de acordo com os caracteres de fim de linha '\n'
	const paragraphs = props.text.split(/[\r\n]+/);

	let footnotesParagraphs = [""];
	if (props.footnotes) footnotesParagraphs = props.footnotes.split(/[\r\n]+/);

	const formatText = (text: string) => {
		//adicionar negrito no texto entre '*'
		let editedText = reactStringReplace(
			text,
			//eslint-disable-next-line
			/\*([^\s][^\*]+?[^\s])\*/g,
			(match, i) => <strong key={match + i}>{match}</strong>
		);
		//adicionar itálico no texto entre '_'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/\_([^\s][^\_]+?[^\s])\_/g,
			(match, i) => <em key={match + i}>{match}</em>
		);
		//tachar o texto entre '~'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/\~([^\s][^\~]+?[^\s])\~/g,
			(match, i) => <del key={match + i}>{match}</del>
		);
		//adicionar sublinhado no texto entre '^'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/\^([^\s][^\^]+?[^\s])\^/g,
			(match, i) => <u key={match + i}>{match}</u>
		);
		//imagem para arquivos entre '&'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/\&([^\s][^\&]+?[^\s])\&/g,
			(match, i) => (
				<StyledChapterImg
					src={
						process.env.PUBLIC_URL +
						`/img/${props.novelPage}/${match}`
					}
					key={match + i}
				/>
			)
		);

		//carta escrita logicament e mão (letra cursiva) '@'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/\@([^\n][^\@]+?[^\n])\@/gm,
			(match, i) => (
				<StyledHandwriting key={match + i}>{match}</StyledHandwriting>
			)
		);

		//carta escrita logicament e mão2 (letra cursiva2) '+'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/\+([^\n][^\+]+?[^\n])\+/gm,
			(match, i) => (
				<StyledHandwriting2 key={match + i}>{match}</StyledHandwriting2>
			)
		);

		//nota do tradutor 'trnt'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/trnt([^\n]+?[^\n])trnt/gm,
			(match, i) => (
				<TranslatorNotes key={match + i}>{match}</TranslatorNotes>
			)
		);

		//surpresa '!!!'
		editedText = reactStringReplace(
			editedText,
			//eslint-disable-next-line
			/\!\!\!([^\n][^\!\!\!]+?[^\n])\!\!\!/gm,
			(match, i) => (
				<StyledSurpriseText key={match + i}>{match}</StyledSurpriseText>
			)
		);

		return editedText;
	};

	return (
		<StyledChapter tabIndex={0}>
			<StyledTopChapter>
				<StyledLink to={`/novel/${props.novelPage}`}>
					<StyledImg
						src={
							process.env.PUBLIC_URL +
							`/img/${props.novelPage}/${props.novelPage}.jpg`
						}
					/>
				</StyledLink>
				<StyledLink to={`/novel/${props.novelPage}`}>
					<StyledNovelName>
						{props.loading ? (
							<Skeleton count={1} />
						) : (
							<span>{props.novel.name_ptbr}</span>
						)}
					</StyledNovelName>
				</StyledLink>
			</StyledTopChapter>
			<StyledTitleContainer>
				{props.loading ? (
					<div>
						<Skeleton count={4} />
					</div>
				) : (
					<div>
						<span className="title">
							Capítulo {props.number} - {props.title}
						</span>

						<br />
						{props?.translator && (
							<span className="translator">
								Tradutor: {props.translator}
							</span>
						)}
						<br />
						{props?.editor && (
							<span className="translator">
								Editor: {props.editor}
							</span>
						)}
						<br />
						<span className="date">
							Postado em {formatDate(props.postdate)}
						</span>
					</div>
				)}

				<div className="btns">
					<Button
						disabled={props.prevCh ? false : true}
						onClick={goToPrevChapter}
						className="prev-next-btn"
						sx={{
							height: 50,
							backgroundColor: colors.primary,
							":hover": {
								bgcolor: colors.secundary,
								color: "white",
							},
							"@media (max-width: 768px)": {
								minWidth: 10,
								padding: 1,
								height: 35,
							},
						}}
						size="small"
						variant="contained"
						color="primary"
					>
						<ArrowBackIosIcon />
					</Button>{" "}
					<Button
						disabled={props.nextCh ? false : true}
						onClick={goToNextChapter}
						className="prev-next-btn"
						sx={{
							height: 50,
							backgroundColor: colors.primary,
							":hover": {
								bgcolor: colors.secundary,
								color: "white",
							},
							"@media (max-width: 768px)": {
								minWidth: 10,
								padding: 1,
								height: 35,
							},
						}}
						size="small"
						variant="contained"
					>
						<ArrowForwardIosIcon />
					</Button>
				</div>
			</StyledTitleContainer>

			<StyledTextContainer>
				{props.loading ? (
					<Skeleton count={35} />
				) : (
					paragraphs?.map((paragraph, index) => (
						<p key={index + paragraph}>
							<span>{formatText(paragraph)}</span>
							<br />
						</p>
					))
				)}

				{footnotesParagraphs[0] !== "" && (
					<>
						<StyledHR />
						<FootNotesContainer>
							<span>Notas: </span>
							<br />
							{footnotesParagraphs?.map((paragraph, index) => (
								<p key={index + paragraph}>
									<span> {formatText(paragraph)}</span>
								</p>
							))}
						</FootNotesContainer>
					</>
				)}
				<StyledChBtnsContainer
					style={{
						backgroundColor: colors.background2,
					}}
				>
					<Button
						disabled={props.prevCh ? false : true}
						onClick={goToPrevChapter}
						className="prev-next-btn"
						sx={
							props.prevCh
								? {
										height: 50,
										color: colors.text2,
										borderRadius: 0,
										border: ` 1px solid ${colors.background2}`,
										":hover": {
											bgcolor: colors.secundary,
											color: "white",
										},
										backgroundColor: colors.primary,
								  }
								: {
										height: 50,
										color: colors.text2,
										borderRadius: 0,
										border: ` 1px solid ${colors.background2}`,
										":hover": {
											bgcolor: colors.primary,
											color: "white",
										},
								  }
						}
						size="medium"
						variant="text"
					>
						Anterior
					</Button>
					<StyledLink to={`/novel/${props.novelPage}`}>
						<Button
							className="toc"
							sx={{
								height: 50,
								color: colors.text2,
								backgroundColor: colors.primary,
								borderRadius: 0,
								border: ` 1px solid ${colors.background2}`,
								":hover": {
									bgcolor: colors.secundary,
									color: "white",
								},
							}}
							size="medium"
							variant="text"
							color="secondary"
						>
							<span className="text-rotate">|||</span>
						</Button>
					</StyledLink>
					<Button
						disabled={props.nextCh ? false : true}
						onClick={goToNextChapter}
						className="prev-next-btn"
						sx={
							props.nextCh
								? {
										height: 50,
										color: colors.text2,
										borderRadius: 0,
										border: ` 1px solid ${colors.background2}`,
										":hover": {
											bgcolor: colors.secundary,
											color: "white",
										},
										backgroundColor: colors.primary,
								  }
								: {
										height: 50,
										color: colors.text2,
										borderRadius: 0,
										border: ` 1px solid ${colors.background2}`,
										":hover": {
											bgcolor: colors.primary,
											color: "white",
										},
								  }
						}
						size="medium"
						variant="text"
						color="secondary"
					>
						Próximo
					</Button>
				</StyledChBtnsContainer>
			</StyledTextContainer>
		</StyledChapter>
	);
};

export default Chapter;
