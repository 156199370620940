import React from "react";
import { INovel } from "./types";
import {
	StyledNovelCard,
	StyledNovelPic,
	StyledLink,
	StyledBottomCard,
} from "./styles";

const imageOnErrorHandler = (
	event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
	event.currentTarget.src = "/img/paneNoSistema.jpg";
};

const NovelCard: React.FC<INovel> = (props) => {
	return (
		<StyledNovelCard>
			<StyledLink to={props.url}>
				<StyledNovelPic
					src={process.env.PUBLIC_URL + props.novelimgsrc}
					onError={imageOnErrorHandler}
				/>
				<StyledBottomCard>
					<div>
						<span> {props.novelname}</span>
					</div>

					{props.showChapter && (
						<span className="chapter">
							Cap. {props.showChapter}
						</span>
					)}
				</StyledBottomCard>
			</StyledLink>
		</StyledNovelCard>
	);
};

export default NovelCard;
