import React, { useEffect } from "react";

const AdRetangle = () => {
	useEffect(() => {
		const pushAd = () => {
			try {
				const adsbygoogle = window.adsbygoogle;
				adsbygoogle.push({});
			} catch (e) {
				console.error(e);
			}
		};

		let interval = setInterval(() => {
			if (window.adsbygoogle) {
				pushAd();
				clearInterval(interval);
			}
		}, 300);

		return () => {
			clearInterval(interval);
		};
	}, []);
	return (
		<ins
			className="adsbygoogle"
			style={{ display: "inline-block", width: "100%", height: "150px" }}
			data-ad-client="ca-pub-8297522658407908"
			data-ad-slot="7454373012"
			data-ad-format="auto"
			data-full-width-responsive="true"
		></ins>
	);
};

export default AdRetangle;
