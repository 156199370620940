import React, { useEffect, useState } from "react";
//import { useParams } from "react-router-dom";
import NovelAbout from "../../components/NovelAbout";
import {
	StyledPage,
	StyledMiddleContentS,
	StyledDisqusWrapper,
} from "../../styles/GlobalStyle";
import ListOfChapters from "../../components/ListOfChapters";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import { NovelI } from "./types";
import { DiscussionEmbed } from "disqus-react";
import { useInView } from "react-intersection-observer";
import useDocumentTitle from "../../utils/useDocumentTitle";

const NovelProfile: React.FC = () => {
	const params = useParams();
	const [loading, setLoading] = useState(true);
	const { novelnameId } = params;
	const { ref: disqusRef, inView: isVisible } = useInView({
		triggerOnce: true,
	});
	// status é pra significar se a novel  já foi finalizada(0), está em hiato (1), está sendo ainda traduzida(2)
	const [novel, setNovel] = useState<NovelI>();

	// document.title = "meu titulo";

	useEffect(() => {
		const getNovel = async () => {
			try {
				const { data } = await api.get("novel/", {
					params: { novel: novelnameId },
				});
				setNovel(data);
				setLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		getNovel();
	}, [novelnameId]);

	useDocumentTitle(novel?.name_ptbr ? novel?.name_ptbr : "");

	return (
		<StyledPage>
			<StyledMiddleContentS>
				<NovelAbout
					novelname={novel?.name}
					novelname_ptbr={novel?.name_ptbr}
					novelimgsrc={`/img/${novel?.key}/${novel?.key}.jpg`}
					author={novel?.author}
					translator="Kam1"
					editor="Kam1"
					sumary={novel?.sumary}
					chaptersqtd={
						novel?.chapters?.length ? novel?.chapters.length : 1
					}
					genre={novel?.genre}
					tags={novel?.tags}
					status={3}
					loading={loading}
				/>
				<ListOfChapters chapters={novel?.chapters} loading={loading} />
				<StyledDisqusWrapper ref={disqusRef}>
					{isVisible && (
						<DiscussionEmbed
							shortname="asgardtraducoes"
							config={{
								url: window.location.href,
								identifier: novelnameId,
								title: novel?.name_ptbr,
								language: "pt_BR",
							}}
						/>
					)}
				</StyledDisqusWrapper>
			</StyledMiddleContentS>
		</StyledPage>
	);
};

export default NovelProfile;
