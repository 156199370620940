import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledChapter = styled.div`
	width: 100%;
	line-height: 24px;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	border-radius: 0.3rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem;
	border: 1px solid ${(props) => props.theme.colors.overlay};

	background-image: linear-gradient(
		${(props) => props.theme.colors.secundary},
		${(props) => props.theme.colors.secundary}
	);
	background-size: 0 100%;
	background-repeat: no-repeat;
	transition: 0.4s;
	&:hover {
		background-size: 100% 100%;
		color: ${(props) => props.theme.colors.text2};
	}
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${(props) => props.theme.colors.text};
`;
