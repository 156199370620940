import React from "react";
import { StyledAllNovels, StyledTitle, StyledHR } from "./styles";
import NovelCard from "../NovelCard";
import { Novels } from "./types";
import NovelCardSkeleton from "../NovelCard/NovelCardSkelegon";

const AllNovels: React.FC<Novels> = (props) => {
	return (
		<StyledAllNovels>
			<StyledTitle>Lista de Novels</StyledTitle>
			<StyledHR />
			{props?.loading &&
				Array(10)
					.fill(0)
					.map((_, index) => <NovelCardSkeleton key={index} />)}

			{props?.novels
				?.sort((a, b) => {
					if (a.name_ptbr && b.name_ptbr)
						return a.name_ptbr < b.name_ptbr
							? -1
							: a?.name_ptbr > b.name_ptbr
							? 1
							: 0;
					else return 0;
				})
				?.map((novel, index) => (
					<NovelCard
						key={index}
						novelimgsrc={`/img/${novel.key}/${novel.key}.jpg`}
						novelname={
							novel.name_ptbr ? novel.name_ptbr : novel.name
						}
						url={`/novel/${novel.key}`}
					/>
				))}
		</StyledAllNovels>
	);
};

export default AllNovels;
