import { useEffect } from "react";

const useDocumentTitle = (title: string) => {
	useEffect(() => {
		if (title === "") document.title = "Asgard Traduções";
		else document.title = `Asgard Traduções | ${title}`;
	}, [title]);
};

export default useDocumentTitle;
