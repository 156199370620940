import React from "react";
import { StyledLatestChapters, StyledTitle, StyledHR } from "./styles";
import NovelCard from "../NovelCard";
import { LastChaptersI } from "./types";
import NovelCardSkeleton from "../NovelCard/NovelCardSkelegon";

const LatestChapters: React.FC<LastChaptersI> = (props) => {
	return (
		<StyledLatestChapters>
			<StyledTitle>Últimos capítulos</StyledTitle>
			<StyledHR />
			{props?.loading &&
				Array(10)
					.fill(0)
					.map((_, index) => <NovelCardSkeleton key={index} />)}
			{props.chapters?.map((chapter, index) => (
				<NovelCard
					key={index}
					showChapter={chapter.number}
					novelimgsrc={`/img/${chapter.novel.key}/${chapter.novel.key}.jpg`}
					novelname={
						chapter.novel.name_ptbr
							? chapter.novel.name_ptbr
							: chapter.novel.name
					}
					url={`/novel/${chapter.novel.key}/${chapter.number}`}
				/>
			))}
		</StyledLatestChapters>
	);
};

export default LatestChapters;
