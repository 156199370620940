import React from "react";
import { StyledAboutContent, StyledTitle } from "./styles";

const AboutContent: React.FC = () => {
	return (
		<StyledAboutContent>
			<StyledTitle>Sobre Asgard Traduções</StyledTitle>
			<span>
				<p>
					Olá, sou Kam1 (é meu nickname em jogos online), um
					desenvolvedor recém formado em Ciências da Computação que
					ama ler novels e mangás nas horas vagas, que certo dia
					pensou: "Por quê não usar as duas coisas que gosto, que é
					programar e ler novels e mangás, e fazer um site para
					traduzir ótimas novels que ninguém nunca deu bola e me fez
					aprender inglês na marra?", bom, este site é o resultado
					desta ideia.
				</p>
				{/* <br />
				<p>
					Se você está lendo isto aqui então quer dizer que até agora
					sou o único tradutor dessa bagaça, assim como também sou o
					único desenvolvedor desse site. Por isso peço que relevem os
					erros que com toda certeza vão existir, seja no site em si,
					ou seja nas traduções. Mas tudo tem um começo, e é
					exatamente no começo que as coisas são mais difíceis, por
					isso peço paciência e, somente caso tiverem condições e
					achar que o site vale a pena continuar, que me ajude com
					qualquer valor monetário, já que ao menos até o momento me
					encontro desempregado :(
				</p> */}
			</span>
		</StyledAboutContent>
	);
};

export default AboutContent;
