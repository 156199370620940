import styled from "styled-components";

export const StyledDefault = styled.div`
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;
`;
