import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledHeader = styled.div`
	height: 4.5rem;
	min-height: 50px;
	width: 100%;
	min-width: 360px;
	background-color: ${(props) => props.theme.colors.primary};
	z-index: 999;
`;

export const Nav = styled.div`
	margin: 0 auto;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	max-width: 1024px;
	padding: 0 22px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${(props) => props.theme.colors.primary};
`;

export const NavLinksContainer = styled.div`
	width: 75%;
	background-color: transparent;
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${(props) => props.theme.colors.text2};
	background-color: transparent;
`;

export const StyledSpan = styled.span`
	padding: 1.3rem;
	background-color: ${(props) => props.theme.colors.primary};
	font-weight: 700;
	height: 100%;

	&:hover {
		border-bottom: 4px solid ${(props) => props.theme.colors.overlay};
	}
`;
