import React from "react";
import AboutContent from "../../components/AboutContent";
import { StyledPage, StyledMiddleContentS } from "../../styles/GlobalStyle";
import useDocumentTitle from "../../utils/useDocumentTitle";

const About: React.FC = () => {
	useDocumentTitle("Sobre");
	return (
		<StyledPage>
			<StyledMiddleContentS>
				<AboutContent />
			</StyledMiddleContentS>
		</StyledPage>
	);
};

export default About;
