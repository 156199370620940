import styled from "styled-components";
import { size } from "../../styles/GlobalStyle";
import { device } from "../../styles/GlobalStyle";

export const StyledListOfChapters = styled.div`
	height: auto;
	max-width: ${size.laptop};
	margin: 1rem auto;
	background-color: ${(props) => props.theme.colors.background2};
	padding: 1rem;
	border-radius: 0.8rem;
	max-width: 980px;

	@media ${device.tablet} {
		min-width: 100%;
	}
`;

export const StyledTitle = styled.h3`
	width: 100%;
	text-align: center;
	margin: 0.5rem;
`;

export const StyledSkeletonChapter = styled.div`
	width: 100%;
	line-height: 24px;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	border-radius: 0.3rem;
	margin-bottom: 0.5rem;
	padding: 0.5rem;
`;
