import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Chapter from "../../components/Chapter";
import api from "../../api/api";
import {
	StyledPage,
	StyledMiddleContentS,
	StyledDisqusWrapper,
	StyledAdsenseWrapper,
} from "../../styles/GlobalStyle";
import { ChapterI } from "./types";
import { DiscussionEmbed } from "disqus-react";
import { useInView } from "react-intersection-observer";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AdRetangle from "../../components/AdRetangle";

const NovelChapter: React.FC = () => {
	const params = useParams();
	const [loading, setLoading] = useState(true);
	const { novelnameId, chapter } = params;
	const { ref: disqusRef, inView: isVisible } = useInView({
		triggerOnce: true,
	});

	const [chapterContent, setChapterContent] = useState<ChapterI>();
	const pageTitle =
		chapterContent?.novel.name_ptbr +
		" - Capítulo " +
		chapterContent?.number +
		": " +
		chapterContent?.name_ptbr;

	useEffect(() => {
		const getChapter = async () => {
			try {
				const { data } = await api.get("novel/chapter", {
					params: { novel: novelnameId, number: chapter },
				});
				setChapterContent(data);
				setLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		getChapter();
	}, [novelnameId, chapter]);

	useDocumentTitle(
		chapterContent?.name_ptbr
			? `${chapterContent?.novel.name_ptbr} - Capítulo ${chapterContent?.number}: ${chapterContent?.name_ptbr}`
			: ""
	);

	const idDisqus = chapter! + novelnameId!;

	return (
		<StyledPage>
			<StyledMiddleContentS>
				<Chapter
					title={
						chapterContent?.name_ptbr
							? chapterContent?.name_ptbr
							: ""
					}
					translator={chapterContent?.translation_team?.translator}
					number={chapterContent?.number ? chapterContent.number : 0}
					text={
						chapterContent?.content
							? chapterContent?.content
							: "Ocorreu um erro e o capítulo não foi carregado."
					}
					nextCh={
						chapterContent?.next_chapter
							? `/novel/${novelnameId}/${chapterContent.next_chapter}`
							: null
					}
					novelPage={novelnameId}
					prevCh={
						chapterContent?.previous_chapter
							? `/novel/${novelnameId}/${chapterContent.previous_chapter}`
							: null
					}
					footnotes={
						chapterContent?.footnotes
							? chapterContent?.footnotes
							: ""
					}
					editor={chapterContent?.translation_team?.editor}
					postdate={
						chapterContent?.created
							? chapterContent?.created
							: new Date()
					}
					novel={
						chapterContent?.novel
							? chapterContent?.novel
							: {
									name: "",
									key: "",
									name_ptbr: "",
							  }
					}
					loading={loading}
				/>
				<StyledAdsenseWrapper>
					<AdRetangle />
				</StyledAdsenseWrapper>

				<StyledDisqusWrapper ref={disqusRef}>
					{isVisible && (
						<DiscussionEmbed
							shortname="asgardtraducoes"
							config={{
								url: window.location.href,
								identifier: idDisqus,
								title: pageTitle,
								language: "pt_BR",
							}}
						/>
					)}
				</StyledDisqusWrapper>
			</StyledMiddleContentS>
		</StyledPage>
	);
};

export default NovelChapter;
