import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing:border-box;
        font-family: 'Open Sans', sans-serif;
    }
    #root, body{
        margin: 0 auto;
        color: ${(props) => props.theme.colors.text};
        background-color: ${(props) => props.theme.colors.background};
    }
`;

export const size = {
	mobileS: "320px",
	mobileM: "375px",
	mobileL: "425px",
	tablet: "768px",
	laptop: "1024px",
	laptopM: "1280px",
	laptopL: "1440px",
	desktop: "2560px",
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tablet: `(max-width: ${size.tablet})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopM: `(max-width: ${size.laptop})`,
	laptopL: `(max-width: ${size.laptopL})`,
	desktop: `(max-width: ${size.desktop})`,
	desktopL: `(max-width: ${size.desktop})`,
};

export const StyledPage = styled.div`
	padding: 0;
	width: 100%;
	height: 100%;
`;

export const StyledMiddleContentS = styled.div`
	width: auto;
	height: auto;
	//max-width: ${size.laptop};
	margin: 0 auto;
	padding: 10px 30px;

	@media ${device.tablet} {
		padding: 10px 20px;
	}
`;

export const StyledMiddleContentM = styled.div`
	width: auto;
	height: auto;
	//max-width: ${size.laptopM};
	margin: 0 auto;
	padding: 10px 30px;

	&.home {
		max-width: 1280px;
		margin: 0 auto;
	}

	@media ${device.tablet} {
		padding: 10px 20px;
	}
`;

export const StyledDisqusWrapper = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
`;

export const StyledAdsenseWrapper = styled.div`
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
`;
