import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import dark from "./styles/themes/dark";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import GlobalStyle from "./styles/GlobalStyle";
import NovelProfile from "./pages/NovelProfile";
import NovelChapter from "./pages/NovelChapter";
import Default from "./pages/Default";
import Novels from "./pages/Novels";
import About from "./pages/About";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Default />,
		errorElement: <ErrorPage />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "novels",
				element: <Novels />,
			},
			{
				path: "about",
				element: <About />,
			},
			{
				path: "novel/:novelnameId",
				element: <NovelProfile />,
			},
			{
				path: "novel/:novelnameId/:chapter",
				element: <NovelChapter />,
			},
		],
	},
]);

const App: React.FC = () => {
	return (
		<ThemeProvider theme={dark}>
			<SkeletonTheme baseColor="#202020" highlightColor="#444">
				<GlobalStyle />
				<RouterProvider router={router} />
			</SkeletonTheme>
		</ThemeProvider>
	);
};

export default App;
