import React, { useEffect, useState } from "react";
import { StyledPage, StyledMiddleContentS } from "../../styles/GlobalStyle";
import AllNovels from "../../components/AllNovels";
import api from "../../api/api";
import useDocumentTitle from "../../utils/useDocumentTitle";

const Novels: React.FC = () => {
	const [allNovels, setAllNovels] = useState([]);
	const [loading, setLoading] = useState(true);
	const getNovels = async () => {
		try {
			const { data } = await api.get("novel/all");
			setAllNovels(data);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		getNovels();
	}, []);
	useDocumentTitle("Novels");
	return (
		<StyledPage>
			<StyledMiddleContentS>
				<AllNovels novels={allNovels} loading={loading} />
			</StyledMiddleContentS>
		</StyledPage>
	);
};

export default Novels;
