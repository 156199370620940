import React, { useState } from "react";
import { StyledContainer } from "./style";
import NotesIcon from "@mui/icons-material/Notes";

interface Props {
	children: React.ReactNode;
}

const TranslatorNotes = ({ children }: Props) => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	if (!open)
		return (
			<StyledContainer onClick={handleClick}>
				<NotesIcon sx={{ fontSize: 15 }} />
			</StyledContainer>
		);

	return (
		<StyledContainer onClick={handleClick}>[{children}]</StyledContainer>
	);
};

export default TranslatorNotes;
