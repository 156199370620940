import styled from "styled-components";
import { device } from "../../styles/GlobalStyle";

export const StyledNovelAbout = styled.div`
	width: 100%;
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
`;

export const StyledNovelPic = styled.img`
	max-width: 14rem;
	min-width: 11rem;
	max-height: 19.5rem;
	min-height: 15rem;
	padding: 0.5rem;
	width: 30%;
	border-radius: 0.8rem;
`;

export const StyledSkeletonNovelPic = styled.div`
	max-width: 14rem;
	width: 12rem;
	min-width: 11rem;
	max-height: 19.5rem;
	height: 17rem;
	min-height: 15rem;
	padding: 0.5rem;
	width: 30%;
	border-radius: 0.8rem;

	&.max-container {
		width: 100%;
		height: 100%;
	}
`;

export const StyledUperContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	align-items: center;

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		height: auto;
	}
`;

export const StyledInformationContainer = styled.div`
	width: 70%;
	height: auto;
	min-height: 18.5rem;
	padding: 0.5rem;
	border-radius: 0.8rem;
	background-color: ${(props) => props.theme.colors.background2};

	@media ${device.tablet} {
		width: 100%;
		background-color: transparent;
		height: auto;
		min-height: auto;
	}
`;

export const StyledTitle = styled.h3`
	width: 100%;
	text-align: center;
`;

export const StyledSubTitle = styled.h5`
	width: 100%;
	text-align: center;
`;

export const StyledSumaryText = styled.h3`
	width: 100%;
	margin-bottom: 0.5rem;
`;

export const StyledHR = styled.hr`
	width: 80%;
	border: 1px solid ${(props) => props.theme.colors.overlay};
	margin: 10px auto;
`;

export const StyledNovelInfo = styled.div`
	width: 100%;
	height: 100%;
	font-size: 0.9rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media ${device.tablet} {
		flex-direction: column;
		height: auto;
		padding-top: 0.9rem;
	}
`;

export const StyledInfoLeft = styled.div`
	width: auto;
	max-width: 33%;
	height: 100%;
	line-height: 24px;
	display: flex;
	flex-direction: column;

	@media ${device.tablet} {
		min-width: 100%;

		span {
			width: auto;
		}
	}
`;

export const StyledInfoRight = styled.div`
	width: 65%;
	height: 100%;
	line-height: 24px;

	@media ${device.tablet} {
		padding-top: 1rem;
		width: 100%;
	}
`;
