import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../styles/GlobalStyle";

export const StyledFooter = styled.div`
	height: 10rem;
	width: 100%;
	background-color: ${(props) => props.theme.colors.primary};

	span {
		background-color: transparent;
		padding: 0.1rem;
	}
	@media ${device.laptop} {
		height: auto;
	}
`;

export const StyledFooterContainer = styled.div`
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
	background-color: ${(props) => props.theme.colors.primary};

	padding: 1rem;
`;

export const StyledBottomContainer = styled.div`
	display: flex;
	background-color: transparent;
	width: 100%;
	height: 30%;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.5rem;
	font-size: 13px;
	@media ${device.laptop} {
		flex-direction: column;
	}
`;

export const StyledIconsContainer = styled.div`
	height: 70%;
	width: 100%;
	padding: 0.5rem;
	background-color: transparent;
	display: flex;
	flex-direction: row;

	@media ${device.laptop} {
		flex-direction: column;
		height: auto;
	}
`;

export const StyledHR = styled.hr`
	width: 100%;
	border: 1px solid ${(props) => props.theme.colors.overlay};
`;

export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${(props) => props.theme.colors.text2};
	background-color: transparent;
	span {
		text-align: center;
		padding: 0;
	}
`;
